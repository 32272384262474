<script>
import Pagination from "v-pagination-3";
import VueMultiselect from 'vue-multiselect'
import DateRangePicker from 'vue3-daterange-picker';
import helpers from "../../helpers.js";

export default {
  name: "OrdersPage",
  components: {
    Pagination, VueMultiselect, DateRangePicker
  },
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      totalItems: 0,
      ordersData: [],
      clientData: [],
      managerData: [],
      draftsmanData: [],
      tempClients: [],
      tempManagers: [],
      tempDraftsman: [],
      dateRange: {
        startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
        endDate: new Date(),
      },
      humanId:"",
      title:"",
      localData: {
        direction: 'ltr',
        format: 'dd-mm-yyyy',
        separator: '-',
        applyLabel: 'Застосвати',
        cancelLabel: 'Відміна',
        weekLabel: 'Т',
        customRangeLabel: 'Період',
        daysOfWeek: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пнт', 'Сб'],
        monthNames: ['Січ', 'Лют', 'Бер', 'Квіт', 'Трав', 'Черв', 'Лип', 'Серп', 'Вер', 'Жовт', 'Лист', 'Груд'],
        firstDay: 0
      }
    }
  },
  methods: {
    async getOrders() {
      this.$http.get(`api/orders/`, {
        params: {
          page: this.currentPage,
          perPage: this.perPage,
          filter: {
            humanId: this.humanId.toUpperCase(),
            title: this.title,
            clientId: this.clientData?._id,
            managerId: this.managerData?._id,
            draftsmanId: this.draftsmanData?._id,
            startDate: this.dateRange.startDate,
            endDate: this.dateRange.endDate,
          }
        }
      }).then(response => {
        this.ordersData = response.data.ordersData;
        this.totalItems = response.data.totalOrders;
        this.currentPage = response.data.currentPage;

      })
    },
    dateToLocale(date) {
      return helpers.convertTime(date)
    },
    async asyncFindClient(query) {
      if (!query || query.length < 3) {
        return
      }
      this.cleanDataRange();
      const clients = await this.$http.get('/api/clients/',{
        params: {
          name: query
        }
      });
      this.tempClients = clients.data.clientData;
    },
    async asyncFindUser(query, type) {
      if (!query || query.length < 3) {
        return
      }
      this.cleanDataRange();
      const users = await this.$http.get('/api/users/', {
        params: {
          type,
          name: query
        }
      });

      if (type === 'manager') {
        this.tempManagers = users.data.data;
      }

      if (type === 'draftsman') {
        this.tempDraftsman = users.data.data;
      }
    },
    setDataRange(range) {
      if (range.startDate && range.endDate) {
        // Парсимо дати з рядків у об'єкти JavaScript Date
        const startDate = new Date(range.startDate);
        const endDate = new Date(range.endDate);

        // Змінюємо час для startDate на '00:00:00'
        startDate.setHours(0, 0, 0, 0);

        // Змінюємо час для endDate на '23:59:59'
        endDate.setHours(23, 59, 59, 999);


        this.dateRange = {
          startDate: startDate,
          endDate: endDate
        };
        this.getOrders();
      }
    },
    getOrdersByHumanId(humanId) {

      if (humanId.length < 2) {
        return
      }
      this.cleanDataRange();
      this.getOrders();
    },

    cleanDataRange() {
      this.dateRange.startDate="";
      this.dateRange.endDate="";
      this.$refs.picker.rangeText = "-----";
    }
  },
  computed: {
    makeCustomRanges() {
      let today = new Date()
      today.setHours(0, 0, 0, 0)

      let yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0);
      return {
        'Сьогодні': [today, today],
        'Вчора': [yesterday, yesterday],
        'За 7 днів': [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6), today],
        'За минулий місяц': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        'За рік': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],

      }

    }
  },
  mounted() {
    this.getOrders();
  }
}
</script>

<template>
  <div class="row align-items-center">
    <div class="col-md-2 mt-3">
      <VueMultiselect
          v-model="this.clientData"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          :options="this.tempClients"
          @select="getOrders"
          @remove="getOrders"
          @search-change="asyncFindClient"
          placeholder="Iм'я клієнта"
          label="name"
          track-by="name"
      />
    </div>
    <div class="col-md-2 mt-3">
      <VueMultiselect
          v-model="this.managerData"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          :options="this.tempManagers"
          @select="getOrders"
          @remove="getOrders"
          @search-change="asyncFindUser($event,'manager')"
          placeholder="Ім'я менеджера"
          label="username"
          track-by="username"
      />
    </div>
    <div class="col-md-2 mt-3">
      <VueMultiselect
          v-model="this.draftsmanData"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          :options="this.tempDraftsman"
          @select="getOrders"
          @remove="getOrders"
          @search-change="asyncFindUser($event,'draftsman')"
          placeholder="Ім'я складальника"
          label="username"
          track-by="username"
      />
    </div>
    <div class="col mt-3">
      <input type="text"
             class="form-control"
             placeholder="ID"
             @input="getOrdersByHumanId($event.target.value)"
             v-model="this.humanId"
      >
    </div>
    <div class="col mt-3">
      <input type="text"
             class="form-control"
             placeholder="Title"
             @input="getOrders"
             v-model="this.title"
      >
    </div>
    <div class="col-md-3 text-end">
      <small>Замовлення за період</small>
      <br>
      <DateRangePicker
          ref="picker"
          :ranges="makeCustomRanges"
          :auto-apply="true"
          :locale-data="localData"
          :date-range="dateRange"
          @select="setDataRange"
          class="w-100"
      >
      </DateRangePicker>
    </div>

    <div class="col-12 my-5">
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Заголовок</th>
          <th scope="col">Клієнт</th>
          <th scope="col">Менеджер</th>
          <th scope="col">Складальник</th>
          <th scope="col">Додано</th>
          <th scope="col">Змінено</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(order,index) in this.ordersData" :key="index">
          <th scope="row">{{ order?.humanId }}</th>
          <td>
            <router-link class="navbar-brand" target="_blank"  :to="'/order/' + order._id">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-external-link"
                   width="22" height="22" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9e9e9e" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6" />
                <path d="M11 13l9 -9" />
                <path d="M15 4h5v5" />
              </svg>

            </router-link>
            {{ order?.title }}
          </td>
          <td>{{ order?.clientId?.name }}</td>
          <td>{{ order?.managerId?.username }}</td>
          <td>{{ order?.draftsmanId?.username }}</td>
          <td>{{ dateToLocale(order?.createdAt) }}</td>
          <td>{{ dateToLocale(order?.updatedAt) }}</td>
        </tr>


        </tbody>
      </table>
    </div>
    <pagination v-model="currentPage"
                :records="totalItems"
                :per-page="perPage"
                :options="{
                 texts: {
                   count: 'Показано з {from} по {to} всього {count} замовлень',
                   first: 'Перша',
                   last: 'Остання',
                   prev: 'Попередня',
                   next: 'Наступна',
                   theme: 'bootstrap4'
                 }
                 }"
                @paginate="getOrders"/>
  </div>
</template>

<style scoped lang="scss">
svg {
  cursor: pointer;
}
.vue-daterange-picker{
  width: 100%;
  text-align: center;
}
</style>